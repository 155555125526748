@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&display=swap');

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

*{
  margin:0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.carousel{
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
}

.divide .ant-divider-horizontal{
  margin: 0px !important;
}

input{
  font-size: 16px !important;
}